/* eslint-disable */
import dynamic from 'next/dynamic';


import GoogleTagManagerNoscript from '@magalu/mixer-modules/build/esm/novisual/GoogleTagManagerNoscript';
import Header from '@magalu/mixer-modules/build/esm/components/ecommerce/Header';
import Heading from '@magalu/mixer-modules/build/esm/components/ecommerce/Heading';
import HotLinks from '@magalu/mixer-modules/build/esm/components/ecommerce/HotLinks';
import LuContent from '@magalu/mixer-modules/build/esm/components/ecommerce/LuContent';
import JsonLd from '@magalu/mixer-modules/build/esm/novisual/JsonLd';
import Footer from '@magalu/mixer-modules/build/esm/components/ecommerce/Footer';
import Favicon from '@magalu/mixer-modules/build/esm/novisual/Favicon';
import Preload from '@magalu/mixer-modules/build/esm/novisual/Preload';
import GoogleTagManagerHead from '@magalu/mixer-modules/build/esm/novisual/GoogleTagManagerHead';

const pageModules = {
  GoogleTagManagerNoscript,
  Header,
  Heading,
  HotLinks,
  LuContent,
  JsonLd,
  Footer,
  Favicon,
  Preload,
  GoogleTagManagerHead,
  BackToTop: dynamic(() => import('@magalu/mixer-modules/build/esm/components/ecommerce/BackToTop'),
    { ssr: false }
  ),
  ZipCodeBar: dynamic(() => import('@magalu/mixer-modules/build/esm/components/ecommerce/ZipCodeBar'),
    { ssr: false }
  ),
  ActionCard: dynamic(() => import('@magalu/mixer-modules/build/esm/components/ecommerce/ActionCard'),
    { ssr: false }
  ),
  Showcase: dynamic(() => import('@magalu/mixer-modules/build/esm/components/ecommerce/Showcase'),
    { ssr: false }
  ),
  ZipCodeInput: dynamic(() => import('@magalu/mixer-modules/build/esm/components/ecommerce/ZipCodeInput'),
    { ssr: false }
  ),
  Loader: dynamic(() => import('@magalu/mixer-modules/build/esm/components/ecommerce/Loader'),
    { ssr: false }
  ),
};

export default pageModules;
